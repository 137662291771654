import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import { Dialog, DialogContent, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import images from '../../assets/images';
import CopyButton from '../../components/utils/CopyButton';
import OverflowTooltip from '../../shared/shared-comp/OverflowTooltip';
import { bmeetUrl } from '../../shared/store/slices/userSlice';
import { fileExt } from '../../shared/util/constant';
import { getInitials, getRandom } from '../../shared/util/util';
import './space-meetings.scss';

export default function MeetingCard({ meet }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openMeeting, setOpenMeeting] = useState(null);

  let re = /(?:\.([^.]+))?$/;

  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl({ [id]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl2);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const openMeetingUrl = () => {
    if (meet?.meeting_provider == 'meet') {
      dispatch(bmeetUrl(meet));
      navigate(`/company/spaces/${params?.id}/meeting-playground/${meet?.id}`);
    } else {
      window.open(meet?.url, '__blank');
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    setOpenMeeting(null);
  };

  const userDisplayIcon = (user) =>
    user?.profile_picture?.profile_picture_url ? (
      <img
        src={user?.profile_picture?.profile_picture_url}
        alt=""
        className="initials"
      />
    ) : (
      <em className="initials">
        {getInitials(user?.name) || getInitials(user?.email)}
      </em>
    );

  //===========================================================
  return (
    <div>
      <div className="meeting-card-section">
        {meet?.agenda && (
          <div className="meeting-card">
            <h3>Agenda</h3>
            <div className="agenda-block">
              <div dangerouslySetInnerHTML={{ __html: meet?.agenda }}></div>
            </div>
          </div>
        )}
        <div className="meeting-card">
          <h3>
            Participants{' '}
            {meet?.users?.length > 0 && (
              <span className="count">
                {meet?.users?.length >= 0 &&
                  meet?.users?.length + (meet?.host?.name ? 1 : 0)}
              </span>
            )}
          </h3>
          <ul className="participants-block">
            {meet?.host?.name && (
              <li>
                {userDisplayIcon(meet?.host)}
                <span>{meet?.host?.name || meet?.host?.email} (Host)</span>
              </li>
            )}
            {meet?.users
              // ?.filter((user) => user?.id !== meet?.host?.id)
              ?.slice(0, 4)
              ?.map((user) => (
                <li key={user?.id ?? getRandom()}>
                  {userDisplayIcon(user)}
                  <OverflowTooltip
                    text={user?.name || user?.email}
                    tooltip={user?.name || user?.email}
                  />
                </li>
              ))}
            {meet?.users?.length > 4 && (
              <li
                style={{ cursor: 'pointer' }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                onClick={handleClick2}
              >
                +{meet?.users?.length - 4}
              </li>
            )}
            <Menu
              style={{ marginLeft: '-8.5%' }}
              className="ideaboard-tooltip table-wrapper"
              id="basic-menu"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {meet?.users?.slice(4, meet?.users?.length)?.map((user) => (
                <MenuItem key={user?.id ?? getRandom()} onClick={handleClose}>
                  {userDisplayIcon(user)}{' '}
                  <OverflowTooltip
                    text={user?.name || user?.email}
                    tooltip={user?.name || user?.email}
                  />
                </MenuItem>
              ))}
            </Menu>
          </ul>
        </div>
        <div className="meeting-card">
          <div className="meeting-links">
            <h3>Meeting URL</h3>
            <div className="link-container">
              <a onClick={openMeetingUrl}>{meet?.url} </a>
              <CopyButton copyText={meet?.url} btnStyle="transparent-btn" />
            </div>
          </div>
        </div>

        {meet?.attachments?.length > 0 && (
          <div className="meeting-card">
            <h3>Attachments</h3>
            {meet?.attachments?.map((att) => (
              <div className="attachement-item" key={att?.id}>
                <div className="attach-icon">
                  <img src={images.IconAttach} alt="Buildcard" />
                </div>
                <div className="attachment-middle">
                  <span className="file-name">{att?.file_identifier}</span>
                  <span className="file-size">{att?.file_size}</span>
                </div>
                <div className="attachement-right">
                  <em
                    className="icon be-vertical-dots"
                    aria-label="open-menu"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => handleClick(e, att?.id)}
                  ></em>
                  <Menu
                    className="three-dot-menu-paper "
                    id="three-dot-menu-whiteboard"
                    anchorEl={anchorEl?.[att?.id]}
                    open={Boolean(anchorEl?.[att?.id])}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem
                      className="toggle-menu-item-row"
                      aria-label="view-btn"
                      onClick={() => {
                        setOpenMeeting(att);
                        handleClose();
                      }}
                      disabled={
                        !fileExt.includes(re.exec(att?.file_identifier)[1])
                      }
                    >
                      <em className="icon be-eye"></em> View
                    </MenuItem>
                    <MenuItem
                      className="toggle-menu-item-row"
                      aria-label="download-btn"
                      onClick={handleClose}
                    >
                      <em className="icon be-download"></em>
                      <a
                        href={att?.url}
                        download={true}
                        style={{ color: 'inherit' }}
                      >
                        Download
                      </a>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <Dialog
        className="attachment-dialog"
        open={Boolean(openMeeting)}
        onClose={handleModalClose}
      >
        <DialogContent className="no-btns">
          <span
            className="modal-close be-close"
            onClick={() => setOpenMeeting(null)}
          ></span>
          <div className="modal-body">
            <div className="connect-with-expert">
              <div className="modal-title">
                <h2>{openMeeting?.file_identifier}</h2>
              </div>
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: openMeeting?.url,
                    fileType: re.exec(openMeeting?.file_identifier)[1],
                  },
                ]}
                config={{
                  header: { disableFileName: true, disableHeader: true },
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
